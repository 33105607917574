import type React from 'react';
// import type { Favorite } from '../components/FavoriteCard';
// import { FavoriteCard } from '../components/FavoriteCard';
// import circuit from '../assets/images/electric_circuit.jpg'

// const favorites: Favorite[] = [
//     {
//         index: 1,
//         title: '電子工作',
//         description: '電子工作をするのが好きです。マイコンを使って家のエアコンや電気を制御したりしてます。',
//         img_link: circuit
//     },
//     {
//         index: 2,
//         title: '電子工作',
//         description: '電子工作をするのが好きです。マイコンを使って家のエアコンや電気を制御したりしてます。',
//         img_link: circuit
//     },
// ]



const Home: React.FC = () => {
    return (
        <div>
            <h1>mochi534 portfolio page</h1>

            <h3>Occupation</h3>
            <p>Engineer</p>

            <h3>Contact</h3>
            <form>
                <p>Mail address</p>
                <input type="text" />
                <p>Content</p>
                <input type="text" />
                <button type="submit">Send</button>
            </form>
            {/* {
                favorites.map((favorite) => (
                    <ul key={favorite.index}>
                        <FavoriteCard favorite={favorite} />
                    </ul>
                ))
            } */}
        </div >
    );
};

export default Home;
